import { useI18n } from 'vue-i18n';

/**
 * Return a function to translate using the i18n module
 * if you don't pass a path, it will translate from the root
 * @param {string} scope locale path (eg: 'pages.publication_drafts.new')
 * @returns {function} (key: string, data: object | null) => string,
 * a function to translate a key in the given scope
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useLocale = (scope?: string): (key: string, data?: any, count?: number) => string => {
  const { t } = useI18n();
  return (key: string, data: object | null = null, count: number | null = null): string => {
    const text = scope ? `${scope}.${key}` : key;
    return count ? t(text, { ...data }, count) : t(text, { ...data });
  };
};
