<script setup lang="ts">
import {
  AlertCircle,
  Archive,
  Book,
  Calendar,
  Check,
  CheckCircle,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  Circle,
  Cloud,
  CloudOff,
  CornerRightUp,
  CornerUpLeft,
  Download,
  Edit3,
  ExternalLink,
  Files,
  Eye,
  EyeOff,
  HardHat,
  Headphones,
  HelpCircle,
  Info,
  LayoutDashboard,
  Lock,
  Minus,
  MoreVertical,
  Paperclip,
  PenBox,
  PenLine,
  Plus,
  RefreshCcw,
  Search,
  Settings,
  Shovel,
  Star,
  Trash2,
  UploadCloud,
  User,
  X,
  XCircle,
} from 'lucide-vue-next';

const iconComponents = {
  AlertCircle,
  Archive,
  Book,
  Calendar,
  Check,
  CheckCircle,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  Circle,
  Cloud,
  CloudOff,
  CornerRightUp,
  CornerUpLeft,
  Download,
  Edit3,
  ExternalLink,
  Files,
  Eye,
  EyeOff,
  HardHat,
  Headphones,
  HelpCircle,
  Info,
  LayoutDashboard,
  Lock,
  Minus,
  MoreVertical,
  Paperclip,
  PenBox,
  PenLine,
  Plus,
  RefreshCcw,
  Search,
  Settings,
  Shovel,
  Star,
  Trash2,
  UploadCloud,
  User,
  X,
  XCircle,
};

import type { Size } from './Icon.types';

interface Props {
  name: string;
  size?: Size;
  strokeWidth?: number;
}
const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  strokeWidth: 1,
});

const iconSize = computed(() => {
  switch (props.size) {
    case 'lg':
      return 24;
    case 'md':
      return 18;
    case 'sm':
      return 12;
    case 'xs':
      return 9;
  }
});

const iconComponent = computed(() => {
  const componentName = props.name.split('-').map((w) => {
    return w.charAt(0).toUpperCase() + w.slice(1);
  }).join('');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore comment
  return iconComponents[componentName];
});
</script>
<template>
  <component
    :is="iconComponent"
    :size="iconSize"
    :stroke-width="strokeWidth"
    class="o-icon"
  />
</template>
